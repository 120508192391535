jQuery( document ).ready(function($) {

  // presave modals
  $('.presave-start').click(function(e){
    e.preventDefault()
    $('.popup').addClass('active')
  })

  $('.close').click(function(){
    $('.popup').removeClass('active')
    $('.popup .start').show()
    $('.popup .apple').hide()
})

  $('.apple-trigger').click(function(e){
    e.preventDefault()
    $('.popup .start').hide()
    $('.popup .apple').fadeToggle()
  })

  // Spotify
  let SMEPreSave = {
    state: null,
    spotify_user: null,
    url_vars: [],
    getURLVars: function (callback, form_id) {
      let hash;
      let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
      for (let i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        this.url_vars.push(hash[0]);
        this.url_vars[hash[0]] = hash[1];
      }
      this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
      this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
      this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
      this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
      this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
      if (typeof callback === 'function') {
        callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
      }
    }
  };
  
  /* String state
   *  The status of the pre-save.
   *  Int ae_member_id
   *  The Appreciation Engine member ID. This can be passed to the forms processor.
   *  String spotify_user
   *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
   *  String deezery_user
   *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
   */
  SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
    // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
    if (state === 'success' || state === 'thank-you') {
      $('.popup').addClass('active')
      $('.popup .start').hide()
      $('.popup .thank-you').fadeToggle()
      setTimeout(() => {
        $('.popup').removeClass('active')
      }, "1500")
    }
  });


  // Apple

  $('.apple-trigger').click(function(e){
    e.preventDefault()
    let AmkPlaylists = ["pl.e189c2be80024d488ba04f3619043e40"];
    let AmkAlbums = [];
    // Create a new SMEAppleMusic object with your parameters
    let sm = new SMEAppleMusic({
      ae: {
        ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
        brand_id: '3443886',
        segment_id: '1498553',
        activities: '{"actions":{"presave":90664},"mailing_list_optins":{"a0S61000001Yq0BEAS":90666}}'
      },
      am: {
        dev_token: '',
        save_mode: 'library',
        custom_playlist_name: '',
        resources: {
          playlists: AmkPlaylists,
          albums: AmkAlbums
        }
      },
      sf: {
        form: '184041',
        default_mailing_list: 'a0S61000001Yq0BEAS'
      },
      smf: {
        campaign_id: '184041',
        campaign_key: '8c6fe2311da7560088d009167671aaac'
      }
    });
    document.getElementById('apple_music_form').addEventListener('submit', function (e) {
      e.preventDefault();
      // Grab the email address value
      const email = document.getElementById('apple_music_email_address').value;
      // Pass the email address and opt in status
      // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
      sm.doActions(email, true).then(function (res) {
        $('.popup .apple').hide()
        $('.popup .thank-you').fadeToggle()
        setTimeout(() => {
          $('.popup').removeClass('active')
        }, "1500")
      }).catch(function (err) {
        // Error - handle your logic here
      });
    });
  })



  // Newsletter
  $(".chris-brown-forms-sign-up").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('.newsletter-error').hide()
        $('.newsletter-success').fadeToggle()
        $('.chris-brown-forms-sign-up').hide()
      },
      error: function (err) {
        $('.newsletter-error').fadeToggle()
      }
    });
  });

  var lastScrollTop = 0;
  document.addEventListener('scroll', function(e) {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop){

      $('.featured-cover').removeClass('out')
      $('.video').removeClass('out')
      $('.featured-cover').addClass('active')
      $('.hero, header, footer').addClass('active')
      
      setTimeout(() => {
        $('.video').addClass('active')
      }, "200")
    } else{
      $('.video').removeClass('active')
      $('.hero, header, footer').removeClass('active')
      $('.video').addClass('out')
      $('.featured-cover').addClass('out')
    }
    lastScrollTop = st <= 0 ? 0 : st;
  });

  $('.video-link').click(function(){
    $('.video').removeClass('active')
    $('.hero, header, footer').removeClass('active')
    $('.video').addClass('out')
    $('.featured-cover').addClass('out')
  })
  
});